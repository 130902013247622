









































  import { Component, Vue, Prop } from 'nuxt-property-decorator'
  import { CombinedFilters, FilteringAttributeType } from '@one/types'
  import { Watch } from 'vue-property-decorator'

  @Component
  export default class OneSelectedFilters extends Vue {
    name: string = 'OneSelectedFilters.vue'

    @Prop({
      type: Object,
      required: true,
    })
    readonly filters!: Record<string, CombinedFilters>;

    @Prop({
      type: Object,
      required: false,
      default: () => ({}),
    })
    readonly selectedFilters!: Record<string, Array<string>>;

    @Prop({
      type: String,
      required: false,
      default: null,
    })
    readonly priceFilter!: string | null;

    oldFilters: Record<string, CombinedFilters> = {}

    @Watch('filters', { immediate: true })
    saveOldFilters(_, oldFilters: Record<string, CombinedFilters>) {
      this.oldFilters = oldFilters
    }

    get hasAttributes() {
      return Object.keys(this.selectedFilters).length > 0 || this.priceFilter
    }

    get hasFilters() {
      return this.filters && Object.keys(this.filters).length || this.oldFilters && Object.keys(this.oldFilters).length
    }

    get priceFilterSimple() {
      // remove GROSS: || NET:
      return this.priceFilter?.split(':')[1]
    }

    getFilterName(key: string): string | null {
      return (this.filters && this.filters[key]?.name) || (this.oldFilters && this.oldFilters[key]?.name) || null
    }

    getFilterAttributeLabel(key: string, attrKey: string) {
      return (this.filters && this.filters[key]?.items[attrKey]?.label) || (this.oldFilters && this.oldFilters[key]?.items[attrKey]?.label) || null
    }

    onAttributeClick(attribute: string, attributeItem: string) {
      this.$emit('filter-changed', {
        type: FilteringAttributeType.MULTICHECKBOX,
        isSelected: false,
        filterValue: attributeItem,
        nameCode: attribute,
      })
    }
  }
